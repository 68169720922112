import { Contact } from "./interfaces/contacts.interface";
import { Countries } from "./interfaces/countries.interface";

export interface AppState {
    countries: Countries[];
    contacts : Contact[];
    userProfile : any;
}

export const intialState : AppState = {
    countries : [],
    contacts :[],
    userProfile : {}
}