import { Action , createReducer, on } from '@ngrx/store'
import { Countries } from '../interfaces/countries.interface';
import * as CountryActions from '../actions/countries.action';
import { AppState, intialState } from '../state';


const countriesReducer = createReducer(
    intialState,
    on(CountryActions.setCountries, (state, { countries }) => {
        // console.log("countries", countries , state);
        return { ...state, countries: countries } } )
);
  
export function reducer(state: AppState | undefined, action: Action) {
    return countriesReducer(state, action);
}




