import { Action , createReducer, on } from '@ngrx/store'
import * as userProfile from '../actions/userProfile.action';
import { AppState, intialState } from '../state';


const userProfileReducer = createReducer(
    intialState,
    on(userProfile.setUserProfile, (state, { userProfile }) => {
        // console.log("user profile", userProfile , state);
        return { ...state, userProfile: userProfile } } )
);
  
export function reducer(state: AppState | undefined, action: Action) {
    return userProfileReducer(state, action);
}




