import { Component, OnInit } from '@angular/core';
import { CommonService } from './services/common.service';
import { AppState } from './store/state';
import { Store } from '@ngrx/store';
import { Countries } from './store/interfaces/countries.interface';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { api } from './crypto-constants/api';
import { SET_COUNTRIES } from './store/actions/countries.action';
import { Contact } from './store/interfaces/contacts.interface';
import { SET_PROFILE } from './store/actions/userProfile.action';
import { Router } from '@angular/router';
import { HostListener } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  implements OnInit{
  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    e.preventDefault();
  }

  title = 'crypto-frontend';
  countries: Observable<Countries[]>;
  public clientAppStatuses : boolean[] = [false];
  // isModalOpen : boolean =  false;
  constructor(
    private store: Store<AppState>,
    public commonService: CommonService,
    private httpClient : HttpClient,
    private router: Router
  ) {
    this.countries = store.select('countries');
    this.countries.subscribe(( countries : any)=>{
      // console.log( countries)
      if(countries.countries && countries.countries.length == 0){
        this.httpClient.get(api.CountriesList).toPromise()
        .then((response: any)=>{
            if(response && response.success){
              store.dispatch( { type : SET_COUNTRIES , countries : response['data'] } )
            }
        })
      }
      else{
        //  console.log( countries)
      }
    })

  }
  ngOnInit(): void {

  }





  // onClose(event : Contact[]){
  //   console.log(event)
  //   this.isModalOpen = false;
  // }

}
