import { Action , createReducer, on } from '@ngrx/store'
import { AppState, intialState } from '../state';
import * as ContactActions from '../actions/contacts.action';

const contactReducer = createReducer(
    intialState,
    on(ContactActions.setContacts, (state, { contacts }) => {
        return { ...state, contacts: contacts } } )
);
  
export function reducer(state: AppState | undefined, action: Action) {
    return contactReducer(state, action);
}




