import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable,pipe } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CommonService } from '../services/common.service';


@Injectable()
export class AuthGuard implements CanActivate {
  constructor( 
    private router: Router,
    private commonService : CommonService
    ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.commonService.isLoggedIn().pipe(
      take(1),
      map((isLoggedIn: boolean) => {
        if (isLoggedIn) {
          
          return true;
        } else {
          if(localStorage.hasOwnProperty('promoCode')){
            const promoCode = JSON.parse(localStorage.getItem('promoCode'));
            if(promoCode.isValidPromoCode){
              this.router.navigate(['/login']);
            }else{
              this.router.navigate(['/signup']);
            }
          }else{
            this.router.navigate(['/signup']);
          }
          return false;

        }
      })
    );
    // this.router.navigate(['/login']);
    // return false;
  }
}