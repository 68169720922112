import { HttpErrorResponse, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { of, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService {
  public token : any =  localStorage.getItem('token');
  private totalRequests = 0;
  constructor(
    private _router : Router,
    private commonService: CommonService
    ) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): any{
    if(request.headers.get("skipLoader") !== "true") {
      this.totalRequests++;
      this.commonService.setLoading(true);
    }
    this.token = localStorage.getItem('token');
    request = request.clone({
      setHeaders: {
        'x-access-token' :  this.token  ?  this.token  : "", 'api-req-from':'CRYPTO'
      },
    });
    return next.handle(request)
    .pipe(
      tap(evt => {
          if (evt instanceof HttpResponse) {
              if(evt.body && evt.body.hasOwnProperty('success')){
                if(evt.status == 401 ){
                  // localStorage.clear();
                  // sessionStorage.clear();
                  // this._router.navigate(['/login']);
                  // setTimeout(() => {
                  // window.location.reload();
                  // }, 100);
                }
              }
              else{
                evt.body['success'] =  false;
                evt.body['data'] =  null;
                evt.body['error'] =  "something went wrong!";
              }
          }
      }),
      finalize(() => {
        if(request.headers.get("skipLoader") !== "true") {
          this.totalRequests--;
        }
        if (this.totalRequests === 0) {
          this.commonService.setLoading(false);
        }
      }),
      catchError((err: any) => {
          if(err instanceof HttpErrorResponse) {
              try {
                if(err.status == 401){
                  // localStorage.clear();
                  localStorage.removeItem('token');
                  this.commonService.showToasterMessage(err.error.message,'top','danger');
                  this._router.navigate(['/login']);
                }
                else if(err.error && err.error.message) {
                  return throwError(err)
                }
                else{
                  return throwError(err);
                }
              } catch(e) {
                return throwError(e);
              }
          }
          return of(err);
      }));
  }
}


